import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Hero from "@components/hero"
import {
  Accordion,
  Card,
  // useContext,
  // useAccordionToggle,
  // AccordionContext,
} from "react-bootstrap"
import ORM from "../images/Online_NARM_Basics_Training_1.webp"
import DLH from "../images/Union 6.png"
import BK from "../images/Union 5.png"
import SK from "../images/Union 4.png"

import Cert from "../images/icons/Certificates.png"
import Cons from "../images/icons/Consultations.png"
import Dir from "../images/icons/Directory.png"
import Disc from "../images/icons/Discount.png"
import Demos from "../images/icons/Live_and_Video.png"
import Exer from "../images/icons/Self_Study.png"
import Supp from "../images/icons/Supplemental_Handouts.png"
import Arc from "../images/icons/Training_ Module.png"
import Obt from "../images/OBT_image.png"

import obtChart from "../images/obt2020_chart.webp"

const ObtPage = ({ location }) => {
  const [id, setId] = useState(0)
  const [arrow, setArrow] = useState(false)

  const toggle = e => {
    setId(e)
    setArrow(!arrow)
  }

  return (
    <>
      <Layout
        location={location}
        hero={<Hero location={location} background={Obt} />}
      >
        <SEO
          title="OBT 2020"
          description="A Level 1 Professional Training that teaches The NeuroAffective Relational Model for Healing Complex Trauma"
        />
        <div className="bnip">
          <div className="container ">
            <div className="row">
              <div className="col-12 py-5">
                <div className="text-center">
                  <h4 className="text-primary font-weight-normal">
                    Online NARM Basics Training
                  </h4>
                  <strong>
                    <h3 className="text-primary">
                      <em>Become a NARM-Informed Professional</em>
                    </h3>
                  </strong>
                </div>
                <p>
                  We are excited to announce the first online professional
                  training in the{" "}
                  <strong> NeuroAffective Relational Model® (NARM®)</strong>, a
                  unique and powerful approach for professionals who work with
                  Complex Trauma (C-PTSD). This continuing education program
                  will support you to become more trauma-informed.
                </p>
                <p>
                  NARM is a theoretical and clinical model for addressing
                  attachment, relational, developmental, cultural and
                  intergenerational trauma, by identifying the survival patterns
                  that cause life-long psychobiological symptoms and
                  interpersonal difficulties. Supported by a greater
                  understanding of the long-term impacts of Adverse Childhood
                  Experiences (ACEs), NARM has become a leading model within the
                  Trauma-Informed Care movement.
                </p>
                <p>
                  Over the past decade, thousands of mental health professionals
                  around the world have been clinically trained in NARM. But
                  NARM is also an educational model that can support others
                  working with Complex Trauma. We have been inspired by so many
                  requests from individuals and organizations that after much
                  creative preparation we are now ready to offer Online NARM
                  Basics Training!
                </p>
                <p>
                  <strong>
                    The intention of this online professional training is to
                    make learning NARM accessible during a time when
                    trauma-informed care is needed more than ever before
                  </strong>
                  . During this time of COVID-19, in-person professional
                  trainings are not an option. Additionally, many professionals
                  have not been able to attend our live events due to travel,
                  financial or work considerations. This live online educational
                  training is designed for those of you in mental health,
                  healthcare, addiction treatment, education, coaching, and
                  other fields working with clients or populations dealing with
                  Complex Trauma.
                </p>
                <p>
                  In this online training, participants will learn more about
                  the changing field of trauma, a deeper understanding of the
                  impacts of Adverse Childhood Experiences and Complex
                  Post-Traumatic Stress Disorder, and how NARM,
                  <strong>
                    one of the first models specifically designed to address
                    C-PTSD
                  </strong>
                  , can support professionals in the growing trauma-informed
                  field.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-obt-section-cta overlay-blue">
              <div className="text-center py-5">
                <div className="col-md-8 col-sm-12 m-auto">
                  <h4 className="text-white">
                    Develop the knowledge and skills to support the healing of
                    Complex Trauma by becoming a NARM-Informed Professional.
                    <div className="pt-5">
                      <a
                        href="https://app.kartra.com/redirect_to/?asset=page&id=HfDMACBg4pZa"
                        className="btn btn-primary inverted shadow arrow text-uppercase"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Apply Now
                      </a>
                    </div>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="t-details"
          className="py-5 slant-bg"
          style={{ background: "#eeeeee" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 td-line">
                <h3 className="text-primary">Training Details</h3>
                <p className="pt-5 mt-4">
                  This professional training has been designed for professionals
                  working with Complex Trauma such as mental health
                  professionals, substance abuse and addiction counselors,
                  doctors, nurses, other medical professionals, first
                  responders, bodyworkers, educators, coaches, spiritual and
                  religious counselors, and more. Please review “Important Terms
                  & Conditions” at the bottom of this page to see if this
                  training is right for you.
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={ORM} alt="Online Ram" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-12 td-line">
              <h3 className="text-primary">Training Dates: </h3>
            </div>
            <div className="row mt-4 pt-5">
              <div className="col-md-6 col-sm-12 px-5 vertical-line pt-4">
                <div className="mx-4">
                  <h4 className="text-orange">
                    <i className="fa fa-calendar mr-3"></i>Online NARM Basics
                    Training Summer 2020
                  </h4>
                  <p className="pr-5">
                    Training sessions will occur 9:00 am - 3:30 pm PDT (12:00 pm
                    - 6:30 pm EDT) each day.
                  </p>
                  <ul className="">
                    <li>Module 1: June 26 - 28, 2020</li>
                    <li>Module 2: July 31 - August 2, 2020</li>
                    <li>Module 3: August 14 - 16, 2020</li>
                    <li>Module 4: September 11 - 13, 2020</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 px-5 pt-4 second-col-td">
                <div className="mx-4">
                  <h4 className="text-orange">
                    <i className="fa fa-calendar mr-3"></i>Online NARM Basics
                    Training Summer 2020
                  </h4>
                  <p className="pr-5">
                    Training sessions will occur 8:00 am - 2:30 pm PST (11:00 am
                    - 5:30 pm EST) each day.
                  </p>
                  <ul className="">
                    <li>Module 1: November 13 - 15, 2020</li>
                    <li>Module 2: December 18 - 20, 2020</li>
                    <li>Module 3: January 15 - 17, 2020</li>
                    <li>Module 4: February 19 - 21, 2020</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="text-center my-5">
              <h4 className="text-primary pt-5">
                Training Location:{" "}
                <span className="font-weight-normal text-dark">
                  This training will be held live online and hosted on Zoom.
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div id="price-details" className="my-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h3 className="text-primary">
                  Pricing Details for the Online NARM Basics Training
                </h3>
                <h4 className="text-orange">
                  Space is limited, so sign up now to reserve your spot!
                </h4>
                <p className="pb-3">
                  We offer <strong> two payment options</strong> for you to
                  choose from as well as an early bird rate for payments
                  completed by the specified deadline.{" "}
                  <strong>
                    {" "}
                    If payment is not made by the early bird deadline, you will
                    owe the standard rate​.
                  </strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 text-center my-2">
                <div className="p-4" style={{ background: "#eeeeee" }}>
                  <h4 className="text-orange">
                    OPTION 1{" "}
                    <span className="font-weight-normal text-primary">
                      {" "}
                      - PAY IN FULL
                    </span>
                  </h4>
                  <hr />
                  <strong className="text-orange">Early Bird Rate:</strong>
                  <h4>$1,499.00 USD</h4>
                  <p>per training</p>
                  <strong className="text-primary">Standard Rate:</strong>
                  <h4>$1,749.00 USD</h4>
                  <p>pre training</p>
                  <hr />
                  <strong className="text-orange">Early Bird Deadlines</strong>
                  <br />
                  <br />
                  <p>
                    Summer 2020:{" "}
                    <em>
                      Unfortunately, the application deadline for Summer 2020
                      has passed.​
                    </em>
                  </p>
                  <p>Winter 2020: October 15, 2020</p>
                  <hr />
                  <a
                    href="https://app.kartra.com/redirect_to/?asset=page&id=HfDMACBg4pZa"
                    className="btn btn-primary shadow arrow text-uppercase my-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 text-center my-2">
                <div className="p-4" style={{ background: "#eeeeee" }}>
                  <h4 className="text-orange">
                    OPTION 2
                    <span className="font-weight-normal text-primary">
                      {" "}
                      - PAY PER MODULE
                    </span>
                  </h4>
                  <hr />
                  <strong className="text-orange">Early Bird Rate:</strong>
                  <h4>$375.00 USD</h4>
                  <p>per module</p>
                  <strong className="text-primary">Standard Rate:</strong>
                  <h4>$437.50 USD</h4>
                  <p>pre module</p>
                  <hr />
                  <strong className="text-orange">Early Bird Deadlines</strong>
                  <br />
                  <br />
                  <p>
                    Summer 2020:{" "}
                    <em>
                      Unfortunately, the application deadline for Summer 2020
                      has passed.​
                    </em>
                  </p>
                  <p>Winter 2020 Module 1: November 2, 2020</p>
                  <hr />
                  <a
                    href="https://app.kartra.com/redirect_to/?asset=page&id=HfDMACBg4pZa"
                    className="btn btn-primary shadow arrow text-uppercase my-3"
                    target="_blank"
                    rel="_blank"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row py-4" style={{ background: "#1381b5" }}>
            <div className="col-md-6 col-sm-12 m-auto text-center">
              <h3 className="text-white">STUDENT, INTERN, & AGENCY PRICING</h3>
              <p className="text-white mb-0">
                We offer special pricing for active students, clinical interns,
                and agencies with three or more training participants! To
                inquire about this pricing options , please email{" "}
                <a href="mailto:admin@narmtrianing.com">
                  <strong className="text-white">admin@narmtrianing.com</strong>
                </a>
              </p>
            </div>
          </div>
        </div>
        <div id="onbtf" style={{ background: "#eeeeee" }}>
          <div className="container py-5">
            <h3 className="text-primary text-center mb-5">
              Online NARM Basics Training Faculty
            </h3>
            <div className="card-group">
              <div className="card border-0 shadow py-5">
                <img
                  src={DLH}
                  alt="Dr. Laurence Heller"
                  className="img-fluid card-img-top"
                />
                <div className="card-body">
                  <h4 className="text-center">Dr.Laurence Heller</h4>
                  <p className="text-center text-primary">
                    <strong>
                      Lead Trainer and Founder of the NeuroAffective Relational
                      Model (NARM)
                    </strong>
                  </p>
                  <p>
                    Dr. Laurence Heller is the creator of the NeuroAffective
                    Relational Model (NARM), Senior Faculty member,
                    International Trainer, and co-author of "Healing
                    Developmental Trauma: How Early Trauma Affects
                    Self-Regulation, Self-Image and the Capacity for
                    Relationship", now published in fifteen different languages.
                    Dr. Heller has conducted NARM trainings and case
                    consultations for thousands of therapists throughout the
                    United States and Europe.
                  </p>
                </div>
              </div>
              <div className="card border-0 shadow py-5">
                <img
                  src={BK}
                  alt="Brad Kammer"
                  className="img-fluid card-img-top"
                />
                <div className="card-body">
                  <h4 className="text-center">Brad Kammer</h4>
                  <p className="text-center text-primary">
                    <strong>NARM Training Director and Senior Trainer</strong>
                  </p>
                  <p>
                    <a
                      href="http://www.body-mindtherapy.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Brad Kammer, LMFT, LPCC, SEP, NMT{" "}
                    </a>{" "}
                    has studied under and taught with his mentor Dr. Laurence
                    Heller for two decades. Brad’s teaching and clinical work
                    has been profoundly inspired by the NeuroAffective
                    Relational Model (NARM). Brad is a NARM Master Therapist,
                    Consultant, and Senior Faculty Trainer, teaching NARM and
                    working with Complex Trauma internationally. Brad is also a
                    professor and community educator, as well as a
                    trauma-informed consultant and trainer for various
                    organizations, including as a Somatic Experiencing Faculty
                    Trainer. Brad is passionate about supporting individuals and
                    communities in the transformation of trauma.
                  </p>
                </div>
              </div>
              <div className="card border-0 shadow py-5">
                <img
                  src={SK}
                  alt="Stefanie Klein"
                  className="img-fluid card-img-top"
                />
                <div className="card-body">
                  <h4 className="text-center">Stefanie Klein</h4>
                  <p className="text-center text-primary">
                    <strong>
                      NARM Assistant Training Director and Trainer
                    </strong>
                  </p>
                  <p>
                    <a
                      href="https://www.stefaniekleinlcsw.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Stefanie Klein{" "}
                    </a>{" "}
                    is a LCSW, SEP, NMT has spent 22 years in her Los Angeles
                    private practice specializing in treating adults with trauma
                    and anxiety disorders. For the past decade, she has had the
                    distinct privilege of being mentored by Dr. Laurence Heller.
                    Stefanie has been honored to assist Dr. Heller in all of his
                    US NARM practitioner trainings and has found NARM to be an
                    extremely effective model for working with attachment,
                    relational, and developmental trauma.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="included py-5" style={{ background: "#1381b5" }}>
          <div className="container">
            <h3 className="text-white text-center">
              Included in the Online NARM Basics Training
            </h3>
            <div className="card-group">
              <div className="card">
                <div className="included-text card-body bg-white">
                  <p className="text-orange">
                    <strong>Training Module Video Archive</strong>
                  </p>
                  <p className="pr-5 mr-5 mb-0 inc-text">
                    Between each module, you will have access to the training
                    video archives that contain recordings of the online basics
                    training. You will have access to these archives for 1 year
                    from the start of the training.
                  </p>
                  <img
                    src={Arc}
                    alt="Training Module Video Archive"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="card">
                <div className="included-text card-body bg-white">
                  <p className="text-orange">
                    <strong>Self-Study Exercises </strong>
                  </p>
                  <p className="pr-5 mr-5 mb-0 inc-text">
                    Journal and other reflective exercises to be completed
                    between training modules.
                  </p>
                  <img
                    src={Exer}
                    alt="Training Module Video Archive"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="card">
                <div className="included-text card-body bg-white">
                  <p className="text-orange">
                    <strong>Supplemental Handouts</strong>
                  </p>
                  <p className="pr-5 mr-5 mb-0 inc-text">
                    Learning materials to support your study and application of
                    NARM principles.
                  </p>
                  <img
                    src={Supp}
                    alt="Training Module Video Archive"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="card">
                <div className="included-text card-body bg-white">
                  <p className="text-orange">
                    <strong>Live and Video NARM Demonstrations</strong>
                  </p>
                  <p className="pr-5 mr-5 mb-0 inc-text">
                    Opportunities to observe and learn from Master NARM
                    Therapists applying NARM principles with a variety of
                    individuals dealing with Complex Trauma.
                  </p>
                  <img
                    src={Demos}
                    alt="Training Module Video Archive"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="card">
                <div className="included-text card-body bg-white">
                  <p className="text-orange">
                    <strong>NARM-Informed Consultations</strong>
                  </p>
                  <p className="pr-5 mr-5 mb-0 inc-text">
                    Case and experiential consultations in the application of
                    NARM principles. See more info below in "Certificate
                    Requirements"
                  </p>
                  <img
                    src={Cons}
                    alt="Training Module Video Archive"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="card">
                <div className="included-text card-body bg-white">
                  <p className="text-orange">
                    <strong>NARM Inner Circle Discount</strong>
                  </p>
                  <p className="pr-5 mr-5 mb-0 inc-text">
                    The NARM Inner Circle is an online learning tool that
                    provides a place for the global NARM Community to connect,
                    learn, and share. Upon completion of Module 1 of the Online
                    NARM Basics Training, you'll receive 50% off your first year
                    membership. Your discount be redeemed anytime throughout the
                    training.
                  </p>
                  <img
                    src={Disc}
                    alt="Training Module Video Archive"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="card">
                <div className="included-text card-body bg-white">
                  <p className="text-orange">
                    <strong>NARM-Informed Professional Certificate</strong>
                  </p>
                  <p className="pr-5 mr-5 mb-0 inc-text">
                    Upon completion of your educational requirements, you will
                    receive your NARM-Informed Professional Certificate.
                  </p>
                  <img
                    src={Cert}
                    alt="Training Module Video Archive"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="card">
                <div className="included-text card-body bg-white">
                  <p className="text-orange">
                    <strong>Listing within the the NARM Directory</strong>
                  </p>
                  <p className="pr-5 mr-5 mb-0 inc-text">
                    Upon Completion of the Online NARM Basics Training, you will
                    be eligible to join the NARM Directory as a NARM-Informed
                    Professional.
                  </p>
                  <img
                    src={Dir}
                    alt="Training Module Video Archive"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="training-schedule py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-sm-12 m-auto">
                <h3 className="text-primary text-center">Training Schedule</h3>
                <p className="text-center px-5">
                  All training days will run live Friday - Sunday. Each training
                  day will have one one-hour lunch and two fifteen-minute
                  breaks.{" "}
                  <strong>
                    {" "}
                    Please explore the topics covered in each module below by
                    clicking on the arrows to the right:
                  </strong>
                </p>
                <Accordion defaultActiveKey="">
                  <Card className="mx-0 TS-accordion">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      onClick={() => toggle(0)}
                      style={{ position: "relative" }}
                    >
                      <h4
                        style={{ position: "relative" }}
                        className="text-orange"
                      >
                        Module 1 Topics
                      </h4>
                      <span
                        className={`${
                          id === 0 && arrow ? "bg-orange" : "bg-default"
                        } float-right text-white`}
                        style={{
                          fontSize: "15px",
                          padding: "10px",
                          borderRadius: "30px",
                          cursor: "pointer",
                          position: "absolute",
                          bottom: "20px",
                          right: "20px",
                        }}
                      >
                        VIEW MODULE{" "}
                        <i
                          style={{
                            border: "1px solid white",
                            borderRadius: "100%",
                          }}
                          className={`ml-2 font-weight-bold ${
                            id === 0 && arrow
                              ? "fa fa-angle-up"
                              : "fa fa-angle-down"
                          }`}
                        ></i>
                      </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <ul>
                          <li>
                            The Trauma-Informed Movement: The Current Field of
                            Trauma
                          </li>
                          <li>
                            The ACEs Study, Complex Trauma (C-PTSD) and
                            Differentiating Shock & Developmental Trauma
                          </li>
                          <li>NARM Organizing Principles</li>
                          <li>NARM 5 Adaptive Survival Styles </li>
                          <li>Toxic Guilt and Shame</li>
                          <li>
                            Self-Rejection, Self-Hatred & Self-Defeating
                            Behaviors
                          </li>
                          <li>
                            Developmental Trauma, Memory & Personal History
                          </li>
                          <li>
                            NARM 4 Pillars: Skills for Addressing Complex Trauma
                          </li>
                          <li>
                            Differentiating NARM from Other Trauma-Informed
                            Approaches
                          </li>
                          <li>
                            NARM Demonstrations, Experiential and Skill Practice
                          </li>
                        </ul>
                        <div>
                          Bonus training with Dr. Heller:{" "}
                          <strong>
                            Special considerations for applying NARM in
                            educational settings for teachers, aides,
                            counselors, administrators, etc. 15
                            <br />
                            <br />
                            <span className="text-orange">CEUs available</span>
                          </strong>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="mx-0 TS-accordion">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="1"
                      onClick={() => toggle(1)}
                      style={{ position: "relative" }}
                    >
                      <h4
                        style={{ position: "relative" }}
                        className="text-orange"
                      >
                        Module 2 Topics
                      </h4>
                      <span
                        className={`${
                          id === 1 && arrow ? "bg-orange" : "bg-default"
                        } float-right text-white`}
                        style={{
                          fontSize: "15px",
                          padding: "10px",
                          borderRadius: "30px",
                          cursor: "pointer",
                          position: "absolute",
                          bottom: "20px",
                          right: "20px",
                        }}
                      >
                        VIEW MODULE{" "}
                        <i
                          style={{
                            border: "1px solid white",
                            borderRadius: "100%",
                          }}
                          className={`ml-2 font-weight-bold ${
                            id === 1 && arrow
                              ? "fa fa-angle-up"
                              : "fa fa-angle-down"
                          }`}
                        ></i>
                      </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <ul>
                          <li>
                            Supporting Personal Agency (“Adult Consciousness”)
                          </li>
                          <li>
                            The ACEs Study, Complex Trauma (C-PTSD) and
                            Differentiating Shock & Developmental Trauma
                          </li>
                          <li>NARM Relational Model</li>
                          <li>
                            Attunement, Misattunement and Unmanaged Empathy{" "}
                          </li>
                          <li>
                            Reflecting on Helping Professionals’ Internal
                            Obstacles and Triggers
                          </li>
                          <li>Complex Trauma & Toxic Shame</li>
                          <li>
                            NARM Pillar #1: Inviting the Intention for Change
                          </li>
                          <li>NARM Pillar #2: Exploring Internal Obstacles</li>
                          <li>Spirituality, Healing and Personal Growth</li>
                          <li>
                            NARM Demonstrations, Experiential and Skill Practice
                          </li>
                        </ul>
                        <div>
                          Bonus training with Dr. Heller:{" "}
                          <strong>
                            Special considerations for applying NARM in
                            healthcare settings for doctors, nurses, aides, etc.
                            <br />
                            <br />
                            <span className="text-orange">
                              15 CEUs available
                            </span>
                          </strong>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="mx-0 TS-accordion">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="3"
                      onClick={() => toggle(3)}
                      style={{ position: "relative" }}
                    >
                      <h4
                        style={{ position: "relative" }}
                        className="text-orange"
                      >
                        Module 3 Topics
                      </h4>
                      <span
                        className={`${
                          id === 3 && arrow ? "bg-orange" : "bg-default"
                        } float-right text-white`}
                        style={{
                          fontSize: "15px",
                          padding: "10px",
                          borderRadius: "30px",
                          cursor: "pointer",
                          position: "absolute",
                          bottom: "20px",
                          right: "20px",
                        }}
                      >
                        VIEW MODULE{" "}
                        <i
                          style={{
                            border: "1px solid white",
                            borderRadius: "100%",
                          }}
                          className={`ml-2 font-weight-bold ${
                            id === 3 && arrow
                              ? "fa fa-angle-up"
                              : "fa fa-angle-down"
                          }`}
                        ></i>
                      </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <ul>
                          <li>Pillar #3: Supporting Personal Agency</li>
                          <li>Pillar #4: Transforming Trauma Patterns</li>
                          <li>Emotional Completion Process</li>
                          <li>Working with Primary and Default Emotions</li>
                          <li>
                            Complex Trauma & Relationship/Intimacy Dynamics
                          </li>
                          <li>Complex Trauma & Family Dynamics</li>
                          <li>Complex Trauma & Working with Children</li>
                          <li>NARM-Informed Education</li>
                          <li>
                            NARM Demonstrations, Experiential and Skill Practice
                          </li>
                        </ul>
                        <div>
                          Bonus training with Dr. Heller:{" "}
                          <strong>
                            Special considerations for applying NARM in recovery
                            settings for substance abuse and addiction
                            counselors
                            <br />
                            <br />
                            <span className="text-orange">
                              15 CEUs available
                            </span>
                          </strong>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="mx-0 TS-accordion">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="4"
                      onClick={() => toggle(4)}
                      style={{ position: "relative" }}
                    >
                      <h4
                        style={{ position: "relative" }}
                        className="text-orange"
                      >
                        Module 4 Topics
                      </h4>
                      <span
                        className={`${
                          id === 4 && arrow ? "bg-orange" : "bg-default"
                        } float-right text-white`}
                        style={{
                          fontSize: "15px",
                          padding: "10px",
                          borderRadius: "30px",
                          cursor: "pointer",
                          position: "absolute",
                          bottom: "20px",
                          right: "20px",
                        }}
                      >
                        VIEW MODULE{" "}
                        <i
                          style={{
                            border: "1px solid white",
                            borderRadius: "100%",
                          }}
                          className={`ml-2 font-weight-bold ${
                            id === 4 && arrow
                              ? "fa fa-angle-up"
                              : "fa fa-angle-down"
                          }`}
                        ></i>
                      </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        <ul>
                          <li>
                            Application of NARM's 4 Pillars for addressing
                            Complex Trauma
                          </li>
                          <li>
                            Connection: Our Deepest Desire and Greatest Fear
                          </li>
                          <li>Attachment and Environmental Failure</li>
                          <li>Intergenerational Trauma</li>
                          <li>
                            The Polyvagal Theory: Developmental Trauma’s Impact
                            on the Body
                          </li>
                          <li>
                            NARM Personality Spectrum Model: Organization of
                            Self
                          </li>
                          <li>Disidentification & Post-Traumatic Growth</li>
                          <li>Heartfulness</li>
                          <li>
                            NARM Demonstrations, Experiential and Skill Practice
                          </li>
                          <li>The Future of Trauma-Informed Care</li>
                        </ul>
                        <div>
                          Bonus training with Dr. Heller:{" "}
                          <strong>
                            Special considerations for applying NARM in other
                            healthcare settings for bodyworkers, coaches and
                            alternative health practitioners
                            <br />
                            <br />
                            <span className="text-orange">
                              15 CEUs available
                            </span>
                          </strong>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div id="dync" className="py-5 overlay-blue">
          <div className="container">
            <div className="row">
              <div className="col-md-10 m-auto col-sm-12">
                <h3 className="text-center text-white">Do You Need CEUs?</h3>
                <div className="dync-text text-white">
                  <i className="fa fa-check fa-2x text-orange"></i>
                  <h4 className="pl-4">60 Continuing Education Units</h4>
                  <p className="pl-4 lead">
                    60 CEUs total / 15 CEUs per module: Available for
                    psychologists, psychotherapists, marriage and family
                    therapists, social workers, personal counselors, educators
                    and healthcare providers. Additional cost for CEUs.
                  </p>
                  <div className="text-center">
                    <a
                      href="https://app.kartra.com/redirect_to/?asset=page&id=HfDMACBg4pZa"
                      className="btn btn-primary inverted shadow arrow text-uppercase"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="certReq"
          className="py-5 slant-bg"
          style={{ background: "#eee" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-10 m-auto col-sm-12">
                <h4 className="text-orange text-center">
                  Certificate Requirements
                </h4>
                <h3 className="text-primary text-center">
                  Become a NARM-Informed Professional
                </h3>
                <p>
                  To become a NARM-Informed Professional, you must attend at
                  least three of the four modules of the training live on Zoom.
                  One module, or the equivalent thereof, may be made-up via
                  video. Additionally, there are four case consultation hours
                  and four experiential consultations required (see info below).{" "}
                  <br />
                  Please note:{" "}
                  <em className="text-orange">
                    {" "}
                    All individual and group consultation are at an additional
                    cost.
                  </em>
                </p>
                <div className="ccr pl-3">
                  <h5 className="text-primary">
                    Case Consultation Requirement:
                  </h5>
                  <ul>
                    <li>
                      Four Case Consultation hours, provided by approved NARM
                      Faculty, are required.
                    </li>
                    <li>
                      Case Consultations are opportunities to meet with NARM
                      Faculty, either in a group format or individually, to
                      reflect on how to apply NARM principles to the
                      individuals, groups and populations you are working with.
                    </li>
                    <li>
                      Group Case Consultations will be held after all four
                      modules and cost $75 each (or $240 for all four). Each
                      Group Case Consultation lasts two hours and provides one
                      credit hour towards your requirements.
                    </li>
                    <li>
                      If you wish to schedule Individual Case Consultations,
                      which cost between $125 and $150 for each meeting, you
                      will be given a list of approved NARM consult providers.
                      Each Individual Case Consultation lasts one hour and
                      provides one credit hour towards your requirements.
                    </li>
                  </ul>
                  <h5 className="text-primary">
                    Case Consultation Requirement:
                  </h5>
                  <Accordion>
                    <Card className="mx-0 TS-accordion ccr-accordion">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="5"
                        onClick={() => toggle(5)}
                        style={{ position: "relative" }}
                      >
                        <p
                          style={{ position: "relative" }}
                          className="text-orange font-weight-bold"
                        >
                          Summer 2020 Group Consultation Dates
                        </p>
                        <span
                          className={`${
                            id === 5 && arrow ? "bg-orange" : "bg-default"
                          } float-right text-white`}
                          style={{
                            fontSize: "15px",
                            padding: "10px",
                            borderRadius: "30px",
                            cursor: "pointer",
                            position: "absolute",
                            bottom: "20px",
                            right: "20px",
                          }}
                        >
                          VIEW MODULE{" "}
                          <i
                            style={{
                              border: "1px solid white",
                              borderRadius: "100%",
                            }}
                            className={`ml-2 font-weight-bold ${
                              id === 5 && arrow
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }`}
                          ></i>
                        </span>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>
                          <ul>
                            <li>
                              Tuesday, July 14th from 10:00 am - 12:00 pm
                              Pacific Time with Dr. Heller
                            </li>
                            <li>
                              Tuesday, August 11th from 10:00 am - 12:00 pm
                              Pacific Time with Brad Kammer
                            </li>
                            <li>
                              Tuesday, September 8th from 10:00 am - 12:00 pm
                              Pacific Time with Dr. Heller
                            </li>
                            <li>Intergenerational Trauma</li>
                            <li>
                              Tuesday, September 22nd from 10:00 am - 12:00 pm
                              Pacific Time with Brad Kammer
                            </li>
                          </ul>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="mx-0 TS-accordion ccr-accordion">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="6"
                        onClick={() => toggle(6)}
                        style={{ position: "relative" }}
                      >
                        <p
                          style={{ position: "relative" }}
                          className="text-orange font-weight-bold"
                        >
                          Summer 2020 Group Consultation Dates
                        </p>
                        <span
                          className={`${
                            id === 6 && arrow ? "bg-orange" : "bg-default"
                          } float-right text-white`}
                          style={{
                            fontSize: "15px",
                            padding: "10px",
                            borderRadius: "30px",
                            cursor: "pointer",
                            position: "absolute",
                            bottom: "20px",
                            right: "20px",
                          }}
                        >
                          VIEW MODULE{" "}
                          <i
                            style={{
                              border: "1px solid white",
                              borderRadius: "100%",
                            }}
                            className={`ml-2 font-weight-bold ${
                              id === 6 && arrow
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }`}
                          ></i>
                        </span>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="6">
                        <Card.Body>
                          <ul>
                            <li>
                              Dates will be posted here once they are announced.
                            </li>
                          </ul>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <h5 className="text-primary">Experiential Consultations:</h5>
                  <ul className="mb-5">
                    <li>
                      Four experiential consultation hours, provided by approved
                      NARM Experiential Consult providers, are required.
                    </li>
                    <li>
                      Experiential Consultations are opportunities to meet with
                      NARM providers individually to demonstrate the various
                      ways NARM principles can be applied, and to debrief how
                      this learning can support your work with Complex Trauma.
                    </li>
                    <li>
                      These consultations will be scheduled on an individual
                      basis, and cost between $125 and $150 for each meeting.
                      Each Experiential Consultation lasts one hour and provides
                      one credit hour towards your requirements.
                    </li>
                    <li>
                      You will be given a list of approved NARM experiential
                      consult providers.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="completeting-training" className="py-5 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-10 m-auto col-sm-12">
                <h3 className="text-primary text-center">
                  Completing the Online NARM Basics Training
                </h3>
                <p>
                  After completion of the Level 1: Online NARM Basics Training,
                  NARM-Informed Professionals are encouraged to list themselves
                  as “NARM-Informed Professionals” in the NARM Practitioner
                  Directory.
                </p>
                <p>
                  NARM-Informed Professionals who are licensed mental health
                  professionals are invited to apply for the Level 2: NARM
                  Therapist Training.
                </p>
              </div>
              <div className="col-md-12">
                <img src={obtChart} className="img-fluid" alt="obtChart" />
                <div className="text-center mt-5">
                  <h5 className="text-primary">
                    <em>
                      Develop the knowledge and skills to support the healing of
                      Complex Trauma by becoming a NARM-Informed Professional.
                    </em>
                  </h5>
                  <a
                    href="https://app.kartra.com/redirect_to/?asset=page&id=HfDMACBg4pZa"
                    className="btn btn-primary shadow arrow text-uppercase mt-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="itc" className="py-5" style={{ background: "#eeeeee" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="text-orange text-center">
                  Important Terms & Conditions
                </h3>
                <p>
                  <em>
                    <small>
                      The NARM Training Institute, LLC reserves the right to
                      approve or deny any application, and/or accept or reject
                      the participation of any person in its sole discretion and
                      in accordance with its policies and the law. <br />
                      <br /> The Online NARM Basics Training is not a licensure
                      or certification training. It is a continuing education
                      program. The aim is to support professionals working with
                      Complex Trauma to learn NARM principles. Becoming a
                      NARM-Informed Professional is not a replacement for a
                      clinical license or professional certification. You are
                      solely responsible for working under your professional
                      license, certification and/or within your scope of
                      practice. If the NARM Training Institute learns of you
                      using NARM outside this agreement, your NARM-Informed
                      Professional designation will be revoked and you will be
                      removed from the NARM Practitioner Directory. <br />
                      <br /> NARM Experiential and Case Consultations are
                      provided within the framework of this professional
                      training, a continuing education program, as learning
                      opportunities for receiving support and guidance for
                      application of NARM in your professional work with Complex
                      Trauma. NARM Experiential Consultations are not a
                      replacement for psychotherapy or counseling, and NARM Case
                      Consultations are not a replacement for professional
                      supervision within your field of practice. If you require
                      psychotherapy, counseling or supervision, it is your
                      responsibility to seek those resources outside the Online
                      NARM Basics Training. <br />
                      <br /> In participating in NARM Experiential and Case
                      Consultations, demonstrations and/or other training
                      exercises, you are agreeing to do so voluntarily and at
                      your own risk. The NARM Training Institute LLC holds no
                      liability or responsibility for your participation or
                      involvement in any demonstration, exercise or
                      consultation, and you accept that any result or no result
                      may occur due to your participation or involvement. You
                      are also agreeing to the understanding that consultations
                      and exercises may include questions regarding your
                      personal history, symptoms, and/or other potentially
                      sensitive information, and that you understand you are
                      under no obligation to participate, may refuse to answer
                      any question, and/or may pause or discontinue your
                      participation at any time.
                    </small>
                  </em>
                </p>
                <p>
                  For further information or for any questions regarding NARM
                  training, please visit <strong> www.narmtraining.com </strong>{" "}
                  Or contact:{" "}
                  <strong>
                    <a href="mailto:admin@narmtraining.com">
                      admin@narmtraining.com
                    </a>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ObtPage
